<template >
  <div class="mt-5">
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.new_m", {
                m: $t("message.service"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-form ref="form" :model="form" :rules="rules" label-position="top">
            <el-row :gutter="20">
                <el-col :span="24">
                  <el-form-item
                  prop="name"
                  :label="columns.name.title"
                  >
                    <el-input
                        :placeholder="columns.name.title"
                        v-model="form.name"
                        size="medium"
                    ></el-input>
                  </el-form-item>     
                </el-col>
                <!-- end-col -->

                <el-col :span="6">
                  <el-form-item
                    prop="money_amount"
                    :label="columns.money_amount.title"
                    >
                      <crm-money-input
                        v-model="form.money_amount"
                        :old="form.money_amount"
                        :size="'medium'"
                      >
                      </crm-money-input>
                  </el-form-item>  
                </el-col>
                <!-- end-col -->
                <el-col :span="6">
                  <el-form-item
                  prop="currency_id"
                  :label="columns.currency_id.title"
                  >
                    <select-currency
                      ref="currencySelect"
                      :size="'medium'"
                      :placeholder="columns.currency_id.title"
                      :id="form.currency_id"
                      v-model="form.currency_id"
                    >
                    </select-currency>
                  </el-form-item>   
                </el-col>
                <el-col :span="6">
                  <el-form-item
                  :label="$t('message.money_amount')"
                  >
                    {{ calculateToSystemMoney }}
                  </el-form-item>   
                </el-col>

            </el-row>
            <!-- end-row -->
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item
                prop="comment"
                :label="columns.comment.title"
                >
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2 }"
                      :placeholder="columns.comment.title"
                      v-model="form.comment">
                    </el-input>
                </el-form-item>   
              </el-col>
              <el-col :span="24">
                <el-form-item
                prop="categories"
                :label="$t('message.categories')"
                >
                    <el-tree
                      ref="category"
                      :data="updatedCategories"
                      show-checkbox
                      node-key="id"
                      :default-checked-keys="[]"
                      :props="defaultProps">
                    </el-tree> 
                </el-form-item>   
              </el-col>
				  <!-- Client Service Price List -->
				  <el-col :span="24">
						<el-divider content-position="left">{{ $t('message.provider_price_list') }}</el-divider>
						<div style="width:50%">
							<select-client
								v-model="client_id"
								:id="client_id"
								:has_provider="true"
								@c-change="selectedClientId"
							></select-client>
						</div>
						<div class="mt-4">
							<el-table :data="items" stripe border style="width: 100%">
								<el-table-column type="index" label="#" width="50">
								</el-table-column>
								<el-table-column :label="$t('message.provider')" prop="full_name">
								</el-table-column>	
								<el-table-column :label="$t('message.total_price')">
									<template slot-scope="item">
										<crm-money-input
											v-model="item.row.money_amount"
											:old="item.row.money_amount"
											:size="'medium'"
										>
										</crm-money-input>
									</template>
								</el-table-column>
								<el-table-column :label="$t('message.currency')">
									<template slot-scope="item">
										<select-currency
											:size="'medium'"
											:id="item.row.currency_id"
											v-model="item.row.currency_id"
										>
										</select-currency>
									</template>
								</el-table-column>
								<el-table-column :label="$t('message.delete')" width="100">
									<template slot-scope="item">
										<el-button type="danger" @click="removeRow(item.row)" icon="el-icon-delete delete-icon" circle size="small"></el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
				  </el-col>
            </el-row>
            <!-- end-row -->
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import productService from '@/utils/mixins/models/productService';

export default {
  mixins: [form, drawer, productService],
  
  methods: {
      ...mapActions({
          save: "productServices/store",
      }),

      afterOpen(){
        this.updateInventory().then(res => {
          this.updatedCategories = JSON.parse(JSON.stringify(this.categories));
        });
      },
  },
};
</script>
<style>
.delete-icon{
	color: white;
}
</style>