import { mapGetters, mapActions } from "vuex";
import _ from 'lodash';
import selectCurrency from "@/components/inventory/select-currency";
import { formatMoney } from "@/filters/index";
import SelectClient from '../../../components/inventory/select-client';

export default {
	components: {
		selectCurrency,
		SelectClient
	 },
	computed: {
		...mapGetters({
		  rules: "productServices/rules",
		  model: "productServices/model",
		  columns: "productServices/columns",
		  categories: "categories/treeInventory",
		}),
		calculateToSystemMoney(){
		  let rate = this.$refs['currencySelect'] ? this.$refs['currencySelect'].currency_selected.rate : 0;
		  return formatMoney((this.form.money_amount * rate), 2);
		}
  
	 },
	 watch : {
		"form.currency_id": {
			 handler: function(newVal, oldVal) {
				setTimeout(() => {
				  if(this.$refs['currencySelect']){
					 this.isCurrencyActive = this.$refs['currencySelect'].currency_selected.active
				  }
				}, 200);
			 },
			 deep: true,
			 immediate: true
		},
		
	 },
	data() {
		return {
		  defaultProps: {
			 children: 'children',
			 label: 'name'
		  },
		  updatedCategories: [],
		  items: [],
		  client_id: null
		};
	 },
	 methods: {
		...mapActions({
			updateInventory: "categories/treeInventory",
			getSelectedClient: 'productServices/getSelectedClient',
		}),
		submit(close = true) {
			if(this.$refs.category){
			  this.form.categories = this.$refs.category.getCheckedKeys();
			}
			this.form['list'] = this.items.map(item => {
			  return {
				  provider_id: item.provider_id,
				  price: item.money_amount,
				  currency_id: item.currency_id
			  }
			});

			this.$refs["form"].validate((valid) => {
				 if (valid) {
					  this.loadingButton = true;
					  this.save(this.form)
							.then((res) => {
								 this.loadingButton = false;
								 this.$alert(res);
								 this.parent().listChanged();
								 if (close) this.close();
							})
							.catch((err) => {
								 this.loadingButton = false;
								 this.$alert(err);
							});
				 }
			});
		},
		selectedClientId(client_id){
			if (!_.find(this.items, {'provider_id': client_id})) {
				this.getSelectedClient({client_id: client_id}).then((res) => {
					if (res.client) {
						let data = res.client;
						let index = this.items.length + 1;
						this.items.push({
							index: index,
							id: null,
							provider_id: data.id,
							full_name: data.full_name,
							money_amount: 0,
							currency_id: 1
						});
						this.client_id = null;
					}
				}).catch((err) => {
					this.$alert(err);
				});
			}
			else {
				this.client_id = null;
			}
		},
		removeRow(item){
			if (this.items.length > 0)
				this.items.splice(this.items.indexOf(item), 1);
		},
		afterLeave(){
			this.$refs['currencySelect'] ? (this.$refs['currencySelect'].currency_selected = {}) : '';
			this.items = [];
		 },
	 }
}