<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2"> {{ $t("message.services") }} </div>
              <div class="block-sarche">
                <div class="header__search">
                  
                   <crm-input
                        :size="'small'"
                        :class="mode ? 'input__day' : 'input__night'"
                        :className="'w100'"
                        v-model="filterForm.search"
                        :icon="'el-icon-search'"
                    ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="d-flex f-end">
			<!-- <el-button @click="priceListDrawer = true" size="small" class="mr-2 padding_none">{{ $t('message.price_lists') }}</el-button> -->
			<el-button @click="downloadPDF" size="small" class="mr-2 padding_none">{{ $t('message.download') + ' PDF' }}</el-button>
             <crm-create-and-column-settings
                  :permission="$options.name"
                  :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                  @c-create="drawerCreate = true"
                  :columns="columns"
                  @c-change="updateColumn"
              >
              </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table 
          class="table-my-code table-bordered"
          :class="mode ? 'table__myday' : 'table__mynight'"
          v-loading="loadingData"
        >
          <thead>
            <tr>
                <th class="w50p" v-if="columns.id.show">
                    {{ columns.id.title }}
                </th>

                <th v-if="columns.name.show">
                    {{ columns.name.title }}
                </th>
                <th v-if="columns.money_amount.show">
                    {{ columns.money_amount.title }}
                </th>
                <th v-if="columns.currency_id.show">
                    {{ columns.currency_id.title }}
                </th>
					 <th v-if="columns.provider_price_list.show">
                    {{ columns.provider_price_list.title }}
                </th>
                <th v-if="columns.comment.show">
                    {{ columns.comment.title }}
                </th>
                <th v-if="columns.created_at.show">
                    {{ columns.created_at.title }}
                </th>

                <th v-if="columns.updated_at.show">
                    {{ columns.updated_at.title }}
                </th>

                <th v-if="columns.settings.show">
                    {{ columns.settings.title }}
                </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                  <el-input
                      clearable
                      size="mini"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.id"
                      :placeholder="columns.id.title"
                      class="id_input"
                  ></el-input>
              </th>

              <th v-if="columns.name.show">
                  <crm-input
                      :placeholder="columns.name.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.name"
                  ></crm-input>
              </th>

              <th v-if="columns.money_amount.show">
                  <crm-input
                    disabled
                    :placeholder="columns.money_amount.title"
                    :class="mode ? 'filter__day' : 'filter__night'"
                  ></crm-input>
              </th>
              <th v-if="columns.currency_id.show">
                  <select-currency
                        :size="'medium'"
                        :placeholder="columns.currency_id.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :id="filterForm.currency_id"
                        v-model="filterForm.currency_id"
                    >
                  </select-currency>
              </th>
				   <th v-if="columns.provider_price_list.show">
                  <crm-input
                    disabled
                    :placeholder="columns.provider_price_list.title"
                    :class="mode ? 'filter__day' : 'filter__night'"
                  ></crm-input>
              </th>

              <th v-if="columns.comment.show">
                  <crm-input
                    disabled
                    :placeholder="columns.comment.title"
                    :class="mode ? 'filter__day' : 'filter__night'"
                  ></crm-input>
              </th>

              <th v-if="columns.created_at.show">
                  <crm-date-picker
                      :placeholder="columns.created_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.created_at"
                  ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                  <crm-date-picker
                      :placeholder="columns.updated_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.updated_at"
                  ></crm-date-picker>
              </th>

              <th
                  class="settinW"
                  v-if="columns.settings.show"
              ></th>
          </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="productService in list" :key="productService.id" class="cursor-pointer">

                <td v-if="columns.id.show">
                    {{ productService.id }}
                </td>

                <td v-if="columns.name.show">
                    {{ productService.name }}
                </td>

                <td v-if="columns.money_amount.show">
                    {{ productService.money_amount | formatNumber(1) }}
                </td>

                <td v-if="columns.currency_id.show">
                    {{ productService.currency ? productService.currency.code : '' }}
                </td>

					<td v-if="columns.provider_price_list.show">
                    <el-button type="text" style="margin: 0; padding: 0" @click="showPriceList(productService.list)">
                        {{ $t('message.view') }} <i class="el-icon-view"></i>
                    </el-button>
                 </td>

                <td v-if="columns.comment.show">
                    {{ productService.comment }}
                </td>
               
                <td v-if="columns.created_at.show">
                    {{ productService.created_at }}
                </td>

                <td v-if="columns.updated_at.show">
                    {{ productService.updated_at }}
                </td>

                <td v-if="columns.settings.show" class="settings-td">
                     
                     <crm-settings
                        :name="$options.name"
                        :model="productService"
                        :actions="actions"
                        :permissionShow="'productServices.update'"
                        :permissionDestroy="'productServices.delete'"
                        @edit="edit"
                        @delete="destroy"
                    ></crm-settings>
                </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
          <!-- <Pagination /> -->
        </div>
      </div>

        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer
               :with-header="false"  
               :visible.sync="drawerCreate" 
               ref="drawerCreate"
               size="70%"
               class="body_scroll_70"
               @opened="drawerOpened('drawerCreateChild')"
               @closed="drawerClosed('drawerCreateChild')"
               >
                <div>
                    <crm-create ref="drawerCreateChild" drawer="drawerCreate"> </crm-create>
                </div>
            </el-drawer>

            <el-drawer
                :with-header="false"
                :visible.sync="drawerUpdate"
                size="70%"
                ref="drawerUpdate"
                class="body_scroll_70"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
                <crm-update
                    :selectedItem="selectedItem"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                ></crm-update>
            </el-drawer> 

            <el-drawer
                :with-header="false"
                :visible.sync="priceListDrawer"
                size="70%"
                ref="priceListDrawer"
                class="body_scroll_70"
                @opened="drawerOpened('priceListDrawerChild')"
                @closed="drawerClosed('priceListDrawerChild')"
            >
                <price-lists
                    :selectedItem="selectedItem"
                    ref="priceListDrawerChild"
                    drawer="priceListDrawer"
                ></price-lists>
            </el-drawer> 
        </div>
        <el-dialog :title="columns.provider_price_list.title" :visible.sync="dialogTableVisible">
            <el-table :data="price_list">
                <el-table-column property="id"  label="ID" width="100"></el-table-column>
                <el-table-column  :label="$t('message.provider')">
                    <template slot-scope="item">
                        {{ item.row.provider ? item.row.provider.full_name : '' }}
                    </template>
                </el-table-column>
                <el-table-column  :label="$t('message.total_price')">
                    <template slot-scope="item">
                        {{ item.row.money_amount | formatNumber(2) }}
                    </template>
                </el-table-column>
                <el-table-column  :label="$t('message.currency')">
                    <template slot-scope="item">
                        <select-currency
                            :size="'medium'"
                            :placeholder="columns.currency_id.title"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            :id="item.row.currency_id"
                            v-model="item.row.currency_id"
                            :disabled="true"
                        ></select-currency>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import PriceLists from "./components/price-lists";
import selectCurrency from "@/components/selects/select-currency";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "productServices",
    mixins: [list],
    components: {
        Pagination,
        CrmCreate,
        CrmUpdate,
        PriceLists,
        selectCurrency
    },

    data() {
        return {
            priceListDrawer: false,
            dialogTableVisible: false,
            price_list: [],
        };
    },

    computed: {
        ...mapGetters({
            list: "productServices/list",
            columns: "productServices/columns",
            pagination: "productServices/pagination",            
            filter: "productServices/filter",
            sort: "productServices/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "productServices/index",
            setPagination: "productServices/setPagination",
            updateSort: "productServices/updateSort",
            updateFilter: "productServices/updateFilter",
            updateColumn: "productServices/updateColumn",
            updatePagination: "productServices/updatePagination",
            show: "productServices/show",
            empty: "productServices/empty",
            delete: "productServices/destroy",
            refreshData: "productServices/refreshData",
			download_pdf: "productServices/downloadPDF"
        }),

        showPriceList(data){
            this.dialogTableVisible = true;
            this.price_list = data;
        },

        downloadPDF(){
            this.download_pdf()
                .then((res) => {
                    const WinPrint = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
                    WinPrint.document.write(res.data);
                    WinPrint.document.close();
                    WinPrint.focus();
                    WinPrint.print();
                    WinPrint.close();
                }).catch((err) => {
                    this.$alert(err);
                });
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('productServices/EMPTY_LIST');
        next()
    },
};
</script>

